// src/pages/redirect.js
import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const Redirect = () => {
  useEffect(() => {
    navigate('/404');
  }, []);

  return null;
};

export default Redirect;
